<template>
	<div class="content flex-space-between">
		<router-view></router-view>
	</div>
</template>

<script>
import {EventBus} from '@/utils/event-bus'
import {postTitleList, postTitle} from '@/service/api/index'
import AsideNavMenu from '@/components/aside/navmenu.vue'

export default {
	components: {AsideNavMenu},
	data() {
		return {
			navMenuList: [],
			navIndex: null,
			navTitle: '',
			listTitle: '',
			navbarId: null,
			listId: null,
			isNavBar: false
		}
	},

}
</script>

<style lang="less" scoped>
.content {
	align-items: flex-start;
}

</style>
